import React from 'react'
import images from 'app/types/images'
import Image from 'next/image'
import { useTranslation } from 'next-i18next'

export const LandingFooter = () => {
  const { t } = useTranslation('common')

  const footer = {
    main: [
      { name: t('home'), href: '/' },
      { name: t('ticket-solution'), href: '/venue/solution' },
      { name: t('platform'), href: '/venue/crm' },
      { name: t('career'), href: '/venue/career' },
      { name: t('about-liveday'), href: '/venue/about' },
      { name: t('contact-us'), href: '/venue/contact_me' },
    ],
    legal: [
      { name: t('terms'), href: '/terms_of_service' },
      { name: t('privacy'), href: '/terms_of_service' },
      { name: t('cookies'), href: '/terms_of_service' },
    ],
  }

  return (
    <footer className="bg-gray-800 pt-3">
      <div className="mx-auto max-w-7xl overflow-hidden px-6 pb-8 sm:pb-12 lg:px-8">
        <div className="flex flex-col items-center justify-center">
          <Image
            src={images.liveday_logo}
            quality={100}
            alt=""
            className="h-32 w-32 text-center"
            width={172}
            height={48}
          />
          <p className="font-inter -mt-5 mb-8 text-base font-normal text-gray-400">
            {t('next-gen-ticket-solution')}
          </p>
        </div>
        <nav
          className="-mb-6 flex columns-2 justify-center gap-x-12"
          aria-label={t('footer') ?? ''}
        >
          {footer.main?.map((item, id) => (
            <div key={id} className="pb-6">
              <a href={item.href} className="text-sm leading-6 text-white">
                {item.name}
              </a>
            </div>
          ))}
        </nav>
        <div className="mt-8 border-t border-gray-700 pt-8 md:flex md:items-center md:justify-between">
          <div className="flex gap-x-6 md:order-2">
            {footer.legal?.map((item, id) => (
              <div key={id} className="pb-6">
                <a href={item.href} className="text-sm leading-6 text-white">
                  {item.name}
                </a>
              </div>
            ))}
          </div>
          <p className="mt-8 text-base text-gray-400 md:order-1 md:mt-0">
            &copy; 2023 - {new Date().getFullYear()} LiveDay.{' '}
            {t('all-rights-reserved')}
          </p>
        </div>
      </div>
    </footer>
  )
}
