import React, { useEffect, useState } from 'react'
import Head from 'next/head'
import { LandingFooter } from '../../components/LandingFooter'
import AOS from 'aos'
import 'aos/dist/aos.css'

import { InlineWidget } from 'react-calendly'
import images from 'app/types/images'
import Image from 'next/image'
import {
  ChevronRightIcon,
  InboxIcon,
  TrashIcon,
  UsersIcon,
} from '@heroicons/react/20/solid'
import Link from 'next/link'
import { LandingNavigation } from '../../components/LandingNavigation'
import { useTranslation } from 'next-i18next'

export default function VenueLandingPage() {
  const { t } = useTranslation('common')

  useEffect(() => {
    AOS.init()
  }, [])

  const dropdownActivePage = '/'

  const [selectedFeature, setSelectedFeature] = useState<number>(0)
  const [fadeClass, setFadeClass] = useState<string>('fade-in')

  const fourthFeatures = [
    {
      name: t('higher-revenue'),
      description: t('higher-revenue-description'),
      icon: InboxIcon,
    },
    {
      name: t('more-audience'),
      description: t('more-audience-description'),
      icon: UsersIcon,
    },
    {
      name: t('less-time'),
      description: t('less-time-description'),
      icon: TrashIcon,
    },
  ]

  const seventhFeatures = [
    {
      title: t('club-manager-falkenberg'),
      name: 'Patrik Lundgren',
      quote: t('falkenberg-quote'),
      picture: 'fffLogo',
    },
    {
      title: t('club-manager-ov-helsingborg'),
      name: 'Mats Engblom',
      quote: t('ov-helsingborg-quote'),
      picture: 'ovhLogo',
    },
    {
      title: t('food-beverage-manager-hif'),
      name: 'Bengt Mårtensson',
      quote: t('hif-quote'),
      picture: 'hifLogo',
    },
  ]

  useEffect(() => {
    const interval = setInterval(() => {
      setFadeClass('fade-out')
      setTimeout(() => {
        setSelectedFeature((prevSelectedFeature) => {
          const nextFeature = prevSelectedFeature + 1
          return nextFeature < seventhFeatures.length ? nextFeature : 0
        })
        setFadeClass('fade-in')
      }, 1000)
    }, 10000)

    return () => clearInterval(interval)
  }, [seventhFeatures.length])

  return (
    <>
      <Head>
        <title>{t('liveday-title')}</title>
        <meta name="description" content={t('liveday-description') ?? ''} />
      </Head>
      <main className="min-w-screen">
        <div className="relative w-screen">
          <div className="min-h-screen sm:h-full sm:min-h-full">
            <div className="relative h-full px-6 lg:px-8">
              <LandingNavigation
                darkText
                dropdownActivePage={dropdownActivePage}
              />

              <div className="container mx-auto flex max-w-7xl">
                <div className="flex flex-row flex-wrap sm:flex-nowrap sm:justify-between">
                  <div className="w-full sm:w-1/2">
                    <div className="sm:py-15 mt-10 py-0 text-center sm:mt-0 sm:block sm:text-left lg:py-20">
                      <h1 className="font-inter h-full text-3xl font-bold tracking-normal text-gray-900 sm:text-6xl">
                        {t('ticket-system-future')}
                      </h1>
                      <p className="font-inter mt-5 text-base font-normal leading-6 text-gray-500">
                        {t('ticket-system-description')}
                      </p>
                      <div className="-mr-5 mt-10 flex flex-col justify-center sm:-mr-48 sm:hidden">
                        <Image
                          src={images.hero_mockup}
                          quality={100}
                          alt=""
                          width={758}
                          height={538}
                        />
                      </div>
                      <div className="mt-10 flex flex-col gap-x-6 sm:flex-row">
                        <Link
                          href="/venue/contact_me#booking"
                          className="bg-brand focus-visible:outline-brand  flex cursor-pointer flex-row justify-center rounded-md py-2 pl-3 text-sm font-medium leading-7 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 sm:justify-start sm:px-5"
                        >
                          {t('book-demo')}
                          <ChevronRightIcon className="ml-2 mt-0.5 h-6 w-6" />
                        </Link>
                        <Link
                          href="/venue/contact_me"
                          className="focus-visible:outline-brand mt-3 flex justify-center rounded-md border border-gray-200 bg-none px-5 py-2 text-sm font-medium leading-7 text-gray-900 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 sm:mt-0 sm:justify-start"
                        >
                          {t('contact-us')}
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="mr-0 hidden flex-col justify-center sm:-mr-48 sm:mt-12 sm:block">
                    <Image
                      src={images.hero_mockup}
                      quality={100}
                      alt=""
                      width={758}
                      height={538}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="my-24 bg-gray-100 py-24 sm:my-32 sm:py-32">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto max-w-4xl">
              <h2 className="text-center text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                {t('first-ticket-system')}
              </h2>
            </div>
            <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
              <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
                {fourthFeatures.map((feature) => (
                  <div key={feature.name} className="flex flex-col">
                    <dt className="flex flex-col items-center text-base font-semibold leading-7 text-gray-900">
                      <div className="mb-2 flex h-10 w-10 items-center justify-center rounded-lg bg-white text-center">
                        <feature.icon
                          aria-hidden="true"
                          className="text-brand h-6 w-6"
                        />
                      </div>
                      {feature.name}
                    </dt>
                    <dd className="mt-1 flex flex-auto flex-col text-base leading-7 text-gray-600">
                      <p className="flex-auto text-center">
                        {feature.description}
                      </p>
                    </dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
        </div>

        <section className="bg-white">
          <div className="mx-auto max-w-screen-xl px-4 py-8 text-center lg:px-6 lg:py-16">
            <div id="testimonial-carousel" className={'relative ' + fadeClass}>
              <style jsx>
                {`
                  .fade-in {
                    opacity: 1;
                    transition: opacity 0.5s ease-in;
                  }

                  .fade-out {
                    opacity: 0;
                    transition: opacity 0.5s ease-out;
                  }
                `}
              </style>
              <div className="relative mx-auto h-52 max-w-screen-md overflow-x-hidden overflow-y-visible rounded-lg sm:h-48">
                <figure className="mx-auto w-full max-w-screen-md">
                  <blockquote>
                    <p className="text-lg font-medium text-gray-900 sm:text-2xl">
                      {seventhFeatures[selectedFeature]?.quote}
                    </p>
                  </blockquote>
                  <figcaption className="mt-6 flex items-center justify-center space-x-3">
                    <div className="relative h-6 w-6 rounded-full">
                      <Image
                        src={
                          images[
                            seventhFeatures[selectedFeature]
                              ?.picture as keyof typeof images
                          ]
                        }
                        alt={seventhFeatures[selectedFeature]?.name ?? ''}
                        className=" text-brand h-6 w-6 rounded-full"
                        fill
                      />
                    </div>
                    <div className="flex items-center divide-x-2 divide-gray-500">
                      <div className="pr-3 font-medium text-gray-900">
                        {seventhFeatures[selectedFeature]?.name}
                      </div>
                      <div className="pl-3 text-sm font-light text-gray-500">
                        {seventhFeatures[selectedFeature]?.title}
                      </div>
                    </div>
                  </figcaption>
                </figure>
              </div>
              <div className="mt-6 flex items-center justify-center">
                <button
                  type="button"
                  className="group mr-4 flex h-full cursor-pointer items-center justify-center focus:outline-none"
                  onClick={() =>
                    setSelectedFeature((currFeature) =>
                      Math.max(currFeature - 1, 0)
                    )
                  }
                >
                  <span className="text-gray-500 hover:text-gray-700">
                    <svg
                      className="h-6 w-6"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    <span className="hidden">Previous</span>
                  </span>
                </button>
                <button
                  type="button"
                  className="group flex h-full cursor-pointer items-center justify-center focus:outline-none"
                  onClick={() =>
                    setSelectedFeature((currFeature) =>
                      Math.min(currFeature + 1, fourthFeatures.length - 1)
                    )
                  }
                >
                  <span className="text-gray-500 hover:text-gray-700">
                    <svg
                      className="h-6 w-6"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    <span className="hidden">Next</span>
                  </span>
                </button>
              </div>
            </div>
          </div>
        </section>

        <div className="relative h-24 min-w-full sm:h-64">
          <Image src={images.landing_middle} quality={100} alt="" fill />
        </div>

        <div className="flex bg-gray-50">
          <div className="container mx-auto flex max-w-7xl flex-row flex-wrap justify-center bg-gray-50 pt-4 sm:mt-20 sm:pt-8 lg:pt-12">
            <div className="mx-auto mt-14 text-center">
              <h2 className="leadin-tight  max-w-3xl px-4 text-3xl font-extrabold tracking-normal text-gray-900 sm:px-0 sm:text-4xl">
                {t('sell-entire-experience')}
              </h2>
              <p className="mx-auto mt-3 max-w-3xl px-4 text-base font-normal leading-normal text-gray-500 sm:px-0 sm:text-xl">
                {t('experience-description')}
              </p>
              <div className="mt-8 flex items-center justify-center gap-x-6">
                <Link
                  href="/venue/solution"
                  className="w-34 bg-brand focus-visible:outline-brand mt-5 flex flex-row rounded-md px-5 py-2 text-base font-medium leading-7 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 sm:mt-0"
                >
                  {t('read-more')}
                  <ChevronRightIcon className="ml-2 mt-0.5 h-6 w-6" />
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="flex bg-gray-50 pb-10 sm:pb-0">
          <div className="container mx-auto flex max-w-7xl flex-row flex-wrap justify-evenly bg-gray-50 py-10 sm:flex-nowrap sm:justify-center sm:pb-20 sm:pt-12 lg:pb-32 lg:pt-16">
            <div className="mr-0 flex w-2/5 flex-col justify-center rounded-md bg-white pr-4 shadow-md sm:mr-5 sm:w-1/2 sm:px-0 sm:pl-6 sm:pr-4">
              <div className="ml-3 mt-3 flex items-center  sm:ml-0">
                <Image
                  src={images.ticket}
                  quality={100}
                  alt=""
                  className="h-10 w-10"
                  width={128}
                  height={128}
                />
                <br />
              </div>
              <h2 className="font-inter mt-4 text-center text-sm font-semibold leading-tight text-gray-900 sm:text-left sm:text-xl">
                {t('ticket-sales')}
              </h2>
              <h2 className="font-inter mb-5 ml-3 mt-2 text-left text-xs font-normal text-gray-500 sm:ml-0 sm:text-base">
                {t('ticket-sales-description')}
              </h2>
            </div>

            <div className="mx-0 flex w-2/5 flex-col justify-center rounded-md bg-white px-4 shadow-md sm:mx-5 sm:w-1/2 sm:flex-col sm:px-0 sm:pl-6 sm:pr-4">
              <div className="mt-3 flex  items-center">
                <Image
                  src={images.jersey}
                  quality={100}
                  alt=""
                  className="h-10 w-10"
                  width={128}
                  height={128}
                />
                <br />
              </div>
              <h2 className="font-inter mt-2 text-left text-sm font-semibold leading-tight text-gray-900 sm:text-xl">
                {t('merchandise')}
              </h2>
              <h2 className="font-inter mb-5 mt-2 text-left text-xs font-normal text-gray-500 sm:text-base">
                {t('merchandise-description')}
              </h2>
            </div>

            <div className="mx-0 mt-5 flex w-2/5 flex-col justify-center rounded-md bg-white px-4 shadow-md sm:mx-5 sm:mt-0 sm:w-1/2 sm:flex-col sm:px-0 sm:pl-6 sm:pr-4">
              <div className="mt-3 flex  items-center">
                <Image
                  src={images.burger}
                  quality={100}
                  alt=""
                  className="h-10 w-10"
                  width={128}
                  height={128}
                />
                <br />
              </div>
              <h2 className="font-inter mt-2 text-left text-sm font-semibold leading-tight text-gray-900 sm:mt-0 sm:text-xl">
                {t('kiosk')}
              </h2>
              <h2 className="font-inter mb-5 mt-2 text-left text-xs font-normal text-gray-500 sm:text-base">
                {t('kiosk-description')}
              </h2>
            </div>

            <div className="ml-0 mt-5 flex w-2/5 flex-col justify-center rounded-md bg-white px-4 shadow-md sm:ml-5 sm:mt-0 sm:w-1/2 sm:flex-col sm:px-0 sm:pl-6 sm:pr-4">
              <div className="mt-3 flex  items-center">
                <Image
                  src={images.cutlery}
                  quality={100}
                  alt=""
                  className="h-10 w-10"
                  width={128}
                  height={128}
                />
                <br />
              </div>
              <h2 className="font-inter mt-2 text-left text-sm font-semibold leading-tight text-gray-900 sm:text-xl">
                {t('restaurant')}
              </h2>
              <h2 className="font-inter mb-5 mt-2 text-left text-xs font-normal text-gray-500 sm:text-base">
                {t('restaurant-description')}
              </h2>
            </div>
          </div>
        </div>

        <div className="container mx-auto mt-20 flex max-w-7xl flex-row flex-wrap justify-between pb-4 sm:mt-48 sm:flex-nowrap sm:pb-24">
          <div
            className="relative right-0 -mr-32 block w-full sm:hidden"
            data-aos="fade-right"
            data-aos-offset="300"
          >
            <Image
              src={images.landing_first}
              quality={100}
              className="relative flex justify-end sm:absolute lg:right-0"
              alt=""
              width={691}
              height={481}
            />
          </div>

          <div
            className="mx-5 mt-5 w-full sm:mx-0 sm:w-4/5"
            data-aos="fade-up"
            data-aos-offset="300"
          >
            <div className="my-8">
              <p className="mb-1 font-semibold leading-tight tracking-tight text-gray-500">
                {t('more-audience-title')}
              </p>
              <p className="text-3xl font-extrabold leading-tight tracking-tight text-gray-900 sm:text-3xl">
                {t('develop-relationship')}
              </p>
              <p className="font-inter mt-6 text-xl font-normal leading-normal text-gray-600">
                {t('crm-description')}
              </p>
            </div>

            <Link
              href="/venue/crm"
              className="bg-brand focus-visible:outline-brand rounded-md px-5 py-3 text-base font-medium leading-7 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
            >
              {t('more-about-crm')}
            </Link>
          </div>

          <div
            className="relative right-0 -mr-32 hidden w-full sm:block"
            data-aos="fade-left"
            data-aos-offset="300"
          >
            <Image
              src={images.landing_first}
              quality={100}
              className="absolute flex justify-end lg:right-0"
              alt=""
              width={691}
              height={481}
            />
          </div>
        </div>

        <div className="container mx-auto mt-20 flex max-w-7xl flex-row flex-wrap justify-between pb-4 sm:mt-48 sm:flex-nowrap sm:pb-24">
          <div
            className="relative right-0 ml-0 w-full sm:-ml-32"
            data-aos="fade-right"
            data-aos-offset="300"
          >
            <Image
              src={images.landing_crowd}
              quality={100}
              className="relative flex sm:absolute lg:left-0"
              alt=""
              width={723}
              height={476}
            />
          </div>

          <div
            className="mx-5 mt-5 w-full sm:mx-0 sm:w-4/5"
            data-aos="fade-up"
            data-aos-offset="300"
          >
            <div className="my-8">
              <p className="mb-1 font-semibold leading-tight tracking-tight text-gray-500">
                {t('less-administration')}
              </p>
              <p className="text-3xl font-extrabold leading-tight tracking-tight text-gray-900 sm:text-3xl">
                {t('all-included-platform')}
              </p>
              <p className="font-inter mt-6 text-xl font-normal leading-normal text-gray-600">
                {t('platform-description')}
              </p>
            </div>

            <Link
              href="/venue/platform"
              className="bg-brand focus-visible:outline-brand rounded-md px-5 py-3 text-base font-medium leading-7 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
            >
              {t('more-about-platform')}
            </Link>
          </div>
        </div>

        <div className="container mx-auto mt-20 flex max-w-7xl flex-row flex-wrap justify-between pb-4 sm:mt-48 sm:flex-nowrap sm:pb-36">
          <div
            className="relative right-0 -mr-32 block w-full sm:hidden"
            data-aos="fade-right"
            data-aos-offset="300"
          >
            <Image
              src={images.landing_phone}
              quality={100}
              className="relative flex justify-end sm:absolute lg:right-0"
              alt=""
              width={691}
              height={481}
            />
          </div>

          <div
            className="mx-5 mt-5 w-full sm:mx-0 sm:w-4/5"
            data-aos="fade-up"
            data-aos-offset="300"
          >
            <div className="my-8">
              <p className="mb-1 font-semibold leading-tight tracking-tight text-gray-500">
                {t('liveday-paydesk')}
              </p>
              <p className="text-3xl font-extrabold leading-tight tracking-tight text-gray-900 sm:text-3xl">
                {t('integrated-pos-system')}
              </p>
              <p className="font-inter mt-6 text-xl font-normal leading-normal text-gray-600">
                {t('pos-system-description')}
              </p>
            </div>

            <Link
              href="/venue/crm"
              className="bg-brand focus-visible:outline-brand rounded-md px-5 py-3 text-base font-medium leading-7 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
            >
              {t('more-about-pos')}
            </Link>
          </div>

          <div
            className="relative right-0 -mr-32 hidden w-full sm:block"
            data-aos="fade-left"
            data-aos-offset="300"
          >
            <Image
              src={images.landing_phone}
              quality={100}
              className="absolute flex justify-end lg:right-0"
              alt=""
              width={691}
              height={481}
            />
          </div>
        </div>

        <section className="bg-white pt-6 sm:pt-24">
          <div className="mx-auto max-w-screen-xl px-4 py-8 lg:py-16">
            <h2 className="mb-8 text-center text-3xl font-extrabold leading-tight tracking-tight text-gray-900 md:text-4xl lg:mb-16">
              {t('game-changers')}
            </h2>
            <div className="grid grid-cols-2 gap-8 text-gray-500 sm:gap-12 md:grid-cols-3 lg:grid-cols-6">
              <div className="flex h-12 w-12 items-center justify-center">
                <Image src="/svg/ov.svg" height={128} width={128} alt="OV" />
              </div>
              <div className="flex h-24 w-24  items-center justify-center">
                <Image
                  src="/svg/vgik.svg"
                  height={128}
                  width={128}
                  alt="VGIK"
                />
              </div>
              <div className="flex h-12 w-12  items-center justify-center">
                <Image src="/svg/uik.svg" height={64} width={64} alt="UIK" />
              </div>
              <div className="flex h-12 w-12  items-center justify-center">
                <Image
                  src="/svg/eskilsminne.svg"
                  height={64}
                  width={64}
                  alt="Eskilsminne"
                />
              </div>
              <div className="flex h-12 w-12  items-center justify-center">
                <Image
                  src="/svg/falkenberg.svg"
                  height={64}
                  width={64}
                  alt="Falkenberg"
                />
              </div>
              <div className="flex h-12 w-12  items-center justify-center">
                <Image src="/svg/hif.svg" height={64} width={64} alt="HIF" />
              </div>
              <div className="flex h-12 w-12  items-center justify-center">
                <Image src="/svg/fch.svg" height={64} width={64} alt="FCH" />
              </div>
              <div className="flex h-12 w-12  items-center justify-center">
                <Image src="/svg/lfc.svg" height={64} width={64} alt="LFC" />
              </div>
              <div className="flex h-12 w-12  items-center justify-center">
                <Image src="/svg/vdff.svg" height={64} width={64} alt="VDFF" />
              </div>
              <div className="flex h-12 w-12  items-center justify-center">
                <Image src="/svg/kdff.svg" height={64} width={64} alt="KDFF" />
              </div>
              <div className="flex h-12 w-12  items-center justify-center">
                <Image src="/svg/kif.svg" height={64} width={64} alt="KIF" />
              </div>
              <div className="flex h-12 w-12  items-center justify-center">
                <Image
                  src="/svg/almtuna.svg"
                  height={64}
                  width={64}
                  alt="Almtuna"
                />
              </div>
            </div>
          </div>
        </section>

        <div id="booking" className="bg-gray-100">
          <div className="mx-auto mt-10 flex max-w-7xl flex-row flex-wrap justify-between pb-4  sm:mt-20 sm:flex-nowrap sm:pb-10">
            <div className="mt-0 w-full px-5 text-center sm:mt-20 sm:w-2/5 sm:px-0 sm:text-left">
              <h2 className="mt-20 text-4xl font-bold tracking-tight text-gray-900">
                {t('book-meeting')}
              </h2>
              <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-600">
                {t('matchday-revenue')}
              </p>
              <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-600">
                {t('book-free-walkthrough')}
              </p>
            </div>

            <div className="mt-20 w-full rounded-md sm:w-2/5">
              <InlineWidget url="https://calendly.com/liveday/demo" />
            </div>
          </div>
        </div>

        <LandingFooter />
      </main>
    </>
  )
}
